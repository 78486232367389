import React from "react"
import AlertBar from "../components/alertBar"
import Layout from "../components/shared/layout-en"
import SEO from "../components/seo"
import ContactSection from "../components/shared/contact-section"
import ModalFormContact from "../components/modal-form-contact"
import Smile from "../images/smileImage.jpg"
import Text from "../text.json"
import "../components/styles/contact.scss"

const Contact = () => {
  const [modalShow, setModalShow] = React.useState(false)
  let pageDescription = "Our team is more than happy to answer all your questions. Contact us now."
  return (
    <>
      <AlertBar french={false} />
      <Layout french={false}>
        <SEO title="Contact Us" description={pageDescription} lang="en" />
        <section className="contact__section">
          <ContactSection
            heading={Text["en"].employees["section-1__heading"]}
            text={Text.en.employees["section-1__text"]}
            textLink={Text.en.employees["section-1__text-link"]}
            buttonText={Text.en.employees["section-1__button"]}
            setModalShow={setModalShow}
          />
          <ContactSection
            image={Smile}
            heading={Text.en.employees["section-2__heading"]}
            text={Text.en.employees["section-2__text"]}
            buttonText={Text.en.employees["section-1__button"]}
            styleClass={"contact__jumbo--white"}
            setModalShow={setModalShow}
          />
          <ModalFormContact
            french={false}
            formTitle={"Contact Form"}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>
      </Layout>
    </>
  )
}

export default Contact
